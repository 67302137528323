import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button, Link, TextField} from "@material-ui/core";
import {PersonAddOutlined} from "@material-ui/icons";
import {AccountInterface} from './AddAccount';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }),
);

const EditAccount = (props: any) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [account, setAccount] = useState<AccountInterface>({
        username: props.account.username,
        password: '',
        retypePassword: '',
        customer: 'orrangemedia'
    })
    const [formSubmited, setFormSubmited] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if(formSubmited) {
            setFormSubmited(false)
            props.callBack();
        }
        setOpen(false);
    };

    return (
        <div>
            <Link component={"button"} color={"primary"} className={classes.link} onClick={handleOpen}>
                <PersonAddOutlined  className={classes.icon}/>
                Edit account
            </Link>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Add Account</h2>
                        <p id="transition-modal-description" >Note: username should start with a letter and may contain
                            only letters and numbers</p>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()

                                fetch(process.env.REACT_APP_API_URL+ `/vault/users`, {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN"),
                                    },
                                    body: JSON.stringify(account)
                                })
                                    .then(response => response.json())
                                    .then(() => {
                                        setFormSubmited(true)
                                    })
                                    .then(() => {
                                        handleClose()
                                    })
                                    .catch(error => setAccount({
                                        username: '',
                                        password: '',
                                        retypePassword: '',
                                        customer: 'orrangemedia'
                                    }))

                            }}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                value={account.username}
                                autoFocus
                                onChange={(e) => setAccount({...account, username: e.target.value})}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="password"
                                autoFocus
                                onChange={(e) => setAccount({...account, password: e.target.value})}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="retypePassword"
                                label="Retype Password"
                                name="retypePassword"
                                type="password"
                                autoComplete="retypePassword"
                                autoFocus
                                onChange={
                                    (e) => setAccount(
                                        {...account, retypePassword: e.target.value}
                                    )}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Create account
                            </Button>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default EditAccount;