import React, {useEffect, useState} from "react";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";

interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
    exact?: boolean;
}

const PrivateRoute = ({Component, path, exact=false}:Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem("BIGNARY_ACCESS_TOKEN"))
    const [authToken, setAuthToken] = useState(localStorage.getItem("BIGNARY_ACCESS_TOKEN"))

    const message = 'Please log in to view this page'

    useEffect(() => {
        if(authToken) {
            fetch(
                process.env.REACT_APP_API_URL + '/auth/isAuthenticated',
                {headers: {Authorization: 'Bearer ' + authToken}}
            )
                .then(response => response.json())
                .then(data => {
                    if (data.email.length > 0) {
                        setIsAuthenticated(true)
                    } else {
                        alert('Response: ' + JSON.stringify(data))
                        console.log(data.code)
                        setIsAuthenticated(false)
                        setAuthToken('')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsAuthenticated(false)
                    setAuthToken('')

                });
        } else {
            setIsAuthenticated(false)
            setAuthToken('')
        }
    }, [authToken])


    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                message,
                                requestedPath: path
                            }
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute;
