import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button, TextField} from "@material-ui/core";
import {PersonAddOutlined} from "@material-ui/icons";

export interface AccountInterface {
    username: string
    password: string
    retypePassword: string
    customer: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            //border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }),
);

const AddAccount = (props: any) => {
    const createAccount = props.createAccount;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [account, setAccount] = useState<AccountInterface>({
        username: '',
        password: '',
        retypePassword: '',
        customer: 'orrangemedia'
    })

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e:any) => {
        e.preventDefault();
        createAccount(account);
        setOpen(false);
    };

    return (
        <div>
            <Button type={"button"} size={"small"} variant={"contained"} color={"primary"}
                    startIcon={<PersonAddOutlined/>}
                    onClick={handleOpen}>
                Add account
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Add Account</h2>
                        <p id="transition-modal-description" >Note: username should start with a letter and may contain
                            only letters and numbers</p>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={(e) => setAccount({...account, username: e.target.value})}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            autoFocus
                            onChange={(e) => setAccount({...account, password: e.target.value})}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="retypePassword"
                            label="Retype Password"
                            name="retypePassword"
                            type="password"
                            autoComplete="retypePassword"
                            autoFocus
                            onChange={
                                (e) => setAccount(
                                    {...account, retypePassword: e.target.value}
                                )}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e)=>handleClose(e)}
                        >
                            Create account
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default AddAccount;