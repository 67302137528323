import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Container,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography, withStyles
} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {DeleteOutlined} from "@material-ui/icons";

export interface StorageData {
    name: string;
}


const StyledTableHead = withStyles((theme) => ({

    body: {
        fontSize: 12,
    },
}))(TableCell);


const AccountShares = () => {
    // @ts-ignore
    const {account} = useParams();

    //const initialPath = '/storage/orrange/orrangemedia/master'
    const [shares, setShares] = useState<StorageData[]>([]);

    const [checked, setChecked] = React.useState<string[]>([]);
    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = () => {
        if (checked.length > 0) {
            setChecked([])
        } else {
            const newChecked = []
            for (let folder of shares) {
                newChecked.push(folder.name)
            }
            setChecked(newChecked);
        }
    };

    useEffect(() => {
        console.log(account)
        fetchShares(account)

        return () => {
            setShares([]);
        }
    }, [account])

    const fetchShares = (account: string) => {
        fetch(
            process.env.REACT_APP_API_URL +
            '/vault/shares?username=' +
            encodeURIComponent(account),
            {headers: {Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN")}}
        )
            .then(response => response.json())
            .then(response => {
                console.log(response);
                setShares(response)
            })
            .catch((error) => setShares([]));
    }

    const removeShares = (username: string) => {
        if (checked.length > 0) {
            const data = {
                'username': username,
                'customer': 'orrangemedia',
                'shares': checked
            };
            console.log(data);
            fetch(process.env.REACT_APP_API_URL + '/vault/shares', {
                method: 'DELETE',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    setChecked([])
                    fetchShares(account)
                })
        }
    }

    const removeShare = (username: string, folder:string) => {
        const data = {
            'username': username,
            'customer': 'orrangemedia',
            'shares': [folder],
        };
        console.log(data)
        fetch(process.env.REACT_APP_API_URL + '/vault/shares', {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                const currentIndex = shares.indexOf({'name': folder});
                const newList = [...shares];
                newList.splice(currentIndex, 1);
                setShares(newList);
            })
    }

    return (
        <Container disableGutters>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h6" gutterBottom>Content shared with <strong>{account}</strong></Typography>
                </Box>

            </Box>


            <TableContainer component={Paper}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableHead>
                                <Checkbox
                                    checked={checked.length > 0}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-label': 'select-all'}}
                                    onClick={handleToggleAll}
                                />
                                <Button type="submit" size={"small"} color={"secondary"}
                                        startIcon={<DeleteOutlined/>}
                                        onClick={() => {
                                            removeShares(account)
                                        }}>
                                    Remove selected shares
                                </Button>
                            </StyledTableHead>
                            <StyledTableHead />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(shares && shares.length > 0) ? shares.map((folder) => (
                                <TableRow key={folder.name}>
                                    <TableCell align={"left"}>
                                        <Checkbox
                                            checked={checked.indexOf(folder.name) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-label': folder.name}}
                                            onClick={handleToggle(folder.name)}
                                        />
                                        {folder.name}
                                    </TableCell>
                                    <TableCell>
                                        <Button type="submit" size={"small"} color={"secondary"}
                                                startIcon={<DeleteOutlined/>}
                                                onClick={() => {
                                                    removeShare(account, folder.name)
                                                }}>
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ),
                        ) :
                            <div>No Content</div>
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Container>
    )
}

export default AccountShares;