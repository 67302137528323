import React from "react";
import {Switch, Route, BrowserRouter} from "react-router-dom";
import {
    createStyles,
    CssBaseline,
    List,
    ListItem,
    makeStyles,
    Theme,
    Toolbar,
    Typography,
    useTheme
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Storage from "../Storage";
import Accounts from "../Accounts";
import AccountShares from "../Accounts/Shares";


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                //width: `calc(100% - ${drawerWidth}px)`,
                //marginLeft: drawerWidth,
                zIndex: theme.zIndex.drawer + 1,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }),
);

const AppRoutes = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <nav>
            <div className={classes.toolbar} />
            <List>
                <ListItem button component="a" href="/">
                    <ListItemText primary="Storage" />
                </ListItem>
                <ListItem button component="a" href="/accounts">
                    <ListItemText primary="Accounts" />
                </ListItem>
                <ListItem button component="a" href="/logout">
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </nav>
    );

    const container = window !== undefined ? () => document.body : undefined;

    // @ts-ignore
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Storage Management
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={Storage} />
                        <Route path="/accounts" component={Accounts} />
                        <Route path="/shares/:account" component={AccountShares} />
                    </Switch>
                </BrowserRouter>
            </main>
        </div>
    );
}

export default AppRoutes;
