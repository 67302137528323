import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Button, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

export interface StorageAccount {
    id: string;
    username: string;
    path: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    shareButton: {
        margin: theme.spacing(1),
    },
}),);

const ShareData = (props: any) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [account, setAccount] = useState<any>({
        username: '',
        folder: '',
        customer: 'orrangemedia',
        mounts: props.folders
    })
    const [formSubmited, setFormSubmited] = useState(false);
    const [accounts, setAccounts] = useState<StorageAccount[]>([])

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL + '/vault/users',
            {headers: {Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN")}}
        )
            .then(response => response.json())
            .then(response => {
                setAccounts(response)
            })
            .catch((error) => setAccounts([]));
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (formSubmited) {
            console.log("Form submited! Clear form!")
            setFormSubmited(false)
            props.callBack(true);
        } else {
            props.callBack(false)
        }
        setOpen(false);
    };

    return (
        <div>
            <Button type={"button"} size={"small"} variant={"contained"} color={"primary"}
                    className={classes.shareButton}
                    onClick={handleOpen}>
                Share folders
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">- Share content</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()

                                fetch(process.env.REACT_APP_API_URL + `/vault/shares`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN"),
                                    },
                                    body: JSON.stringify(account)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        setFormSubmited(true)
                                    })
                                    .then(() => {
                                        handleClose()
                                    })
                                    .catch(error => setAccount({
                                        username: '',
                                        folder: ''
                                    }))

                            }}
                        >
                            <Autocomplete
                                id={"username"}
                                options={accounts}
                                getOptionLabel={(option) => option.username}
                                renderInput={(params) => <TextField
                                    {...params}
                                    required
                                    label={"Choose account"}
                                    variant={"outlined"}
                                />}

                                onChange={(event, newValue) => {
                                    if ( newValue != null ) {
                                        console.log(account)
                                        account.username = newValue.username;
                                        account.mounts = props.folders
                                        console.log(account)
                                    }
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="folder"
                                label="Folder"
                                name="folder"
                                autoComplete="folder"
                                autoFocus
                                onChange={(e) => setAccount({...account, folder: e.target.value})}
                            />
                            <Typography>Leave empty to share to main folder</Typography>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Share content
                            </Button>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default ShareData;