import React, {useEffect, useState} from "react";
import {
    Breadcrumbs,
    Card,
    CardHeader,
    Checkbox,
    Container,
    createStyles,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    makeStyles,
    Theme
} from "@material-ui/core";
import {DeleteOutlined, HomeOutlined} from "@material-ui/icons";
import {grey} from "@material-ui/core/colors";
import ShareData from "./ShareData";

export interface StorageData {
    name: string;
}

export interface StorageAccount {
    id: string;
    username: string;
    path: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        gridRoot: {
            flexGrow: 1,
        },
        gridItem: {
            "&:hover": {
                backgroundColor: grey[100],
            },
        },
        cardRoot: {
            minHeight: '50vw',
        },

    }),
);

export default function Storage() {
    const classes = useStyles();

    const initialPath = '/storage/orrange/orrangemedia/master'
    const [data, setData] = useState<StorageData[]>([])
    const [path, setPath] = useState("")

    const [checked, setChecked] = React.useState<string[]>([]);
    const [toMount, setToMount] = React.useState<string[]>([]);

    const [sharedData, setSharedData] = useState(false)

    const handleToggle = (value: string) => () => {
        if (value.indexOf('m-') >= 0) {
            value = value.substr(value.lastIndexOf('/')+1)

        }

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);

        const currentMountIndex = toMount.indexOf('m-' +path + '/' +value);
        const newMount = [...toMount];

        if (currentMountIndex === -1) {
            newMount.push('m-'+path + '/' + value);
        } else {
            newMount.splice(currentMountIndex, 1);
        }

        setToMount(newMount);
    };

    useEffect(() => {
        //console.log(path)

        fetch(
            process.env.REACT_APP_API_URL +
            '/vault/files?path=' +
            encodeURIComponent(initialPath + path),
            {headers: {Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN")}}
        )
            .then(response => response.json())
            .then(response => {
                setData(response)
            })
            .catch((error) => setData([]));

        return () => {
            setData([]);
        }
    }, [path])


    useEffect(()=>{
        console.log(sharedData)
        setSharedData(false)
        setChecked([])
        setToMount([])
    }, [sharedData])

    return (
        <Container disableGutters>
            <Grid container spacing={2} className={classes.gridRoot}>
                <Grid item xs={12}>
                    <Breadcrumbs separator="/">
                        <Link component={"button"} underline={"none"} className={classes.link} color={"primary"}
                              key={"home"}
                              onClick={() => setPath("")}>
                            <HomeOutlined className={classes.icon}/>
                            Orrange Media
                        </Link>
                        {path.length>0 ? path.substr(1).split('/').map((folder) =>(
                            <Link component={"button"} underline={"none"} className={classes.link} color={"primary"}
                                  key={path.substr(0, path.indexOf(folder))}
                                  onClick={() => setPath(path.substr(0, path.indexOf(folder)) +folder)}>
                                {folder}
                            </Link>
                        )) : ''}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" className={classes.gridRoot}>
                <Grid item xs={12} sm={6}>
                    <Card className={classes.cardRoot}>
                        <CardHeader
                            subheader={"Storage Folders"}
                        />

                        <Divider light className={classes.divider}></Divider>

                        <List dense>
                            {data.map((folder) => (
                                    <ListItem key={path + '/' + folder.name} className={classes.gridItem} role="listitem">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={checked.indexOf(folder.name) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{'aria-labelledby': folder.name}}
                                                onClick={handleToggle(folder.name)}
                                            />
                                        </ListItemIcon>
                                        <Link component={"button"} underline={"none"} className={classes.link}
                                              onClick={() => setPath(path + '/' + folder.name)}>
                                            {folder.name}
                                        </Link>
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card className={classes.cardRoot}>
                        <CardHeader
                            subheader={"Selected folders"}
                        />

                        <Divider light className={classes.divider}></Divider>

                        <List>
                            {toMount.map((checkedfFolder) => (
                                    <ListItem key={checkedfFolder} className={classes.gridItem} role="listitem"
                                              alignItems={"flex-start"}>
                                        <Link component={"button"} underline={"none"} className={classes.link}
                                              align={"left"}
                                              onClick={handleToggle(checkedfFolder)}>
                                            <DeleteOutlined/>
                                            {checkedfFolder.substring(2)}
                                        </Link>
                                    </ListItem>
                                ),
                            )}
                        </List>

                        {checked.length>0 && <ShareData callBack={setSharedData} folders={toMount}/> }
                    </Card>
                </Grid>
            </Grid>

        </Container>
    )
}