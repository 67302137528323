import React, {useEffect, useState} from "react";
import {
    Box,
    createStyles,
    Link,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";
import {DeleteOutlined} from "@material-ui/icons";
import AddAccount, {AccountInterface} from "./AddAccount";
import EditAccount from "./EditAccount";

export interface StorageAccount {
    id: string;
    username: string;
    path: string;
}

const StyledTableHead = withStyles((theme) => ({

    body: {
        fontSize: 18,
    },
}))(TableCell);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
    }),
);

export default function Accounts() {
    const classes = useStyles();

    const [data, setData] = useState<StorageAccount[]>([])
    useEffect(() => {
        fetchData();
        return () => {
            setData([]);
        }
    }, [])

    const fetchData = () => {
        fetch(
            process.env.REACT_APP_API_URL + '/vault/users',
            {headers: {Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN")}}
        )
            .then(response => response.json())
            .then(response => {
                setData(response);
            })
            .catch(() => setData([]));
    };

    const deleteAccount = (username: string) => {

        const data = {
            'username': username,
            'customer': 'orrangemedia',
        };
        fetch(process.env.REACT_APP_API_URL + '/vault/users', {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(() => {
                fetchData();
            })
    };

    const createAccount = (account: AccountInterface) => {
        console.log(account);
        const newAccount:StorageAccount = {
            id: "000",
            username: account.username,
            path: "",
        };

        fetch(process.env.REACT_APP_API_URL + `/vault/users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + localStorage.getItem("BIGNARY_ACCESS_TOKEN"),
            },
            body: JSON.stringify(account)
        })
            .then(response => response.json())
            .then((response) => {
                console.log(response);
                setData([...data, newAccount]);
            })
    };

    return (
        <div>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h6" gutterBottom>Storage Accounts</Typography>
                </Box>
                <Box>
                    <AddAccount createAccount={createAccount}/>
                </Box>
            </Box>

            <TableContainer component={Paper}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableHead>Username</StyledTableHead>
                            <StyledTableHead/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data && data.length > 0) && data.map((account) => (
                                <TableRow key={account.username}>
                                    <TableCell component="th">
                                        <Link color={"primary"}
                                              href={"/shares/" + account.username}>
                                            <strong>{account.username}</strong>
                                        </Link>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Box display={"flex"} flexDirection={"row"} justifyContent="flex-end">
                                            <EditAccount callBack={fetchData} account={account}/>
                                            &nbsp;&nbsp;
                                            <Link component={"button"} color={"secondary"} className={classes.link}
                                                  onClick={() => {
                                                      deleteAccount(account.username)
                                                  }}>
                                                <DeleteOutlined className={classes.icon}/> Delete
                                            </Link>
                                        </Box>

                                    </TableCell>
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>

            </TableContainer>

        </div>


    )
}
