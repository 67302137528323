import React, {useEffect} from "react";

const Logout = (props:any) => {

    useEffect(() => {
        localStorage.clear()
        props.history.push("/login")
    });

    return (<div></div>)
}

export default Logout;